<template>
    <div class="video-editer-page">
        <div class="editer-toolbar">
            <div class="back-btn" @click="goBackEvent" ><!-- $router.go(-1) -->
                     <i class="el-icon-back"></i>
                 </div>
            <div class="flex-1">
                <span class="doc-title">{{ info?.title }}</span>
                <svg-icon class="margin-l-10" name="ib-video-translate"></svg-icon>
                <span class="margin-l-10">{{info?.duration}}</span>
                <span class="margin-l-10 fonts-12 color-999">
                    <template v-if="saveLoading"><i class="el-icon-loading"></i> 自动保存中</template>
                    <template v-else>
                        <span class="margin-l-10 fonts-12" style="color-999">
                            <svg-icon name="ib-check-circle"></svg-icon>
                        </span>
                        已保存
                    </template>
                </span>
            </div>
            <div>
                <el-button round type="primary" size="small" @click="handleConfirmTranslation">合成视频</el-button>
            </div>
        </div>
        <div class="editer-wrapper">
        
            <div class="editer-main">
                <div class="editer-header">
                    <span class="left">序号</span>
                    <div class="right">
                        <!-- <span>原文（{{ info?.sourceLang.name }}） - 译文（{{ info?.targetLang.name }}）</span>      -->
                        <span>原文（{{ info?.sourceLang.name }}）</span>
                        <svg-icon  class="icon" name="ib-transfer"></svg-icon>
                        <span>译文（{{ info?.targetLang.name }}）</span>
                        <el-tooltip placement="bottom" class="tooltip">
                            <div slot="content">译文可进行编辑，编辑文本需根据时间段长度修改字数，<br/>过长或过短会导致生成视频的配音或快或过慢。</div>
                            <svg-icon name="ib-help-o" className="help-icon"></svg-icon>
                        </el-tooltip>
                    </div>
                </div>
                <div class="editer-content">
                    <template v-for="(item, index) in list">
                        <div class="item" @click="handleItemClick(item)" :key="index">
                            <div class="flex">
                            <div class="item-number width-50">{{ index + 1 }}</div>
                             <div class="time-container">
                                <div class="ib-time">
                                    <svg-icon name="ib-time"></svg-icon>
                                </div>
                                <div class="time-bucket-wrapper">
                                    <div class="time-bucket">
                                    {{item.beginTimeAxis}} - {{item.endTimeAxis}}</div>
                                        
                                </div>
                            </div>
                            </div>
                            <div class="sentence-content">
                                <div class="source"><div class="width-50"></div><div class="flex-1">{{ item.sourceText }}</div></div>
                                <!-- <div class="target" contenteditable="true" @input="targetInputEvent($event, item, index)" @blur="targetBlurEvent($event, item, index)">{{ item.targetText }}</div>
                                 -->
                                 <div class="target">
                                    <div class="icon-edit width-50"><svg-icon name="ib-translate-text"></svg-icon></div>
                                    <div class="target-text" contenteditable="true" @input="targetInputEvent($event, item, index)" @blur="targetBlurEvent($event, item, index)">
                                       {{ item.targetText }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                 <!-- <div class="editer-footer" v-if="list.length > 0 && total > pageSize"> -->
                  <div class="editer-footer" v-if="list.length > 0 && total > pageSize">
                    <el-pagination 
                        background 
                        @current-change="handleCurrentChange"
                        layout="total, prev, pager, next"
                        :page-size="pageSize"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
            <div class="video-main">
                <div class="video-header">原视频</div>
                <div class="video-container">
                    <video ref="videoPlayer" :src="info?.originalVideo" @timeupdate="onTimeUpdate"></video>
                </div>
                <div class="video-controls">
                    <span  @click="togglePlay">
                        <svg-icon 
                            :name="isPlaying ? 'ib-video-pause' : 'ib-video-play'" 
                            class="play-pause-btn"
                        ></svg-icon>
                    </span>
                    <div class="time-display">
                        <span v-if="TimeLoop.startTime && TimeLoop.endTime">
                            {{ TimeLoop.startTimeAxis }} - {{ TimeLoop.endTimeAxis }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- 添加确认弹窗 -->
        <alert-dialog ref="confirmDialog"
                      :message="'确认已完成文本校对，开始配音和合成视频？'"
                      :type="'confirm'"
                      buttonName="合成视频"            
                      @confirmEvent="startTranslation"
                      @cancelEvent="cancelTranslation">
        </alert-dialog>
    </div>

</template>
<script>
import { debounce } from 'lodash';
import { toolsApi } from '@/utils/api';
import AlertDialog from '@/components/AlertDialog';
import tools from '@/utils/tools'

export default {
    components:{ AlertDialog},
    data() {
        return {
            info: {},
            list: [],
            pageNumber: 1,
            pageSize: 20,
            total: 0,
            saveLoading: false,
            confirmLoading: false,
            isPlaying: false,
            TimeLoop: {
                startTime: '',
                endTime: '',
                startTimeAxis: '',
                endTimeAxis: '',

            },
        }
    },
    methods: {
        async initTaskInfo() {
            let url = `${toolsApi.queryVideoTaskInfo}?taskId=${this.$route.query.id}`;
            const res = await this.$ajax.get(url);
            if (res.status === 200) {
                this.info = res.data;
            }
        },
        async initSubtitles() {
            let url = `${toolsApi.queryVideoSubtitlePage}?taskId=${this.$route.query.id}&pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`;
            const res = await this.$ajax.get(url);
            if (res.status === 200) {
                this.list = res.data.records;
                this.total = res.data.total;
            }
        },
        handleCurrentChange(num){
            this.pageNumber = num;
            this.initSubtitles();
        },
        goBackEvent(){
            if(this._events.backEvent){
                this.$emit('backEvent');
            }else{
                this.$router.go(-1);
            }
        },
        timeToSeconds(time) {
            const [hours, minutes, secondsAndMilliseconds] = time.split(':');
            const [seconds, milliseconds] = secondsAndMilliseconds.split('.');

            const hoursInSeconds = parseInt(hours, 10) * 60 * 60;
            const minutesInSeconds = parseInt(minutes, 10) * 60;
            const secondsInSeconds = parseInt(seconds, 10);
            const millisInSeconds = parseInt(milliseconds, 10) / 1000;

            return hoursInSeconds + minutesInSeconds + secondsInSeconds + millisInSeconds;
        },
        togglePlay() {
            const video = this.$refs.videoPlayer;
            if (this.isPlaying == false) {
                video.play();
                this.isPlaying = true;
            } else {
                video.pause();
                this.isPlaying = false;
            }
        },

        onTimeUpdate() {
            const video = this.$refs.videoPlayer;
            this.currentTime = video.currentTime;
            
            if (this.TimeLoop.startTime !== '' && this.TimeLoop.endTime !== '') {
                if (video.currentTime >= this.TimeLoop.endTime) {
                    video.currentTime = this.TimeLoop.startTime;
                }
            }
        },

        handleItemClick(item) {
            const video = this.$refs.videoPlayer;
            this.TimeLoop.startTime = this.timeToSeconds(item.beginTimeAxis);
            this.TimeLoop.endTime = this.timeToSeconds(item.endTimeAxis);
            this.TimeLoop.startTimeAxis = item.beginTimeAxis;
            this.TimeLoop.endTimeAxis = item.endTimeAxis;
            video.currentTime = this.TimeLoop.startTime;
            video.play();
            this.isPlaying = true;
        },

    
        async saveSentence(e, item, index) {
            let url = toolsApi.saveEditVideoSentence;
            let postData = {
                taskDetailId: item.taskDetailId,
                targetText: e.target.innerText,
            };
            this.saveLoading = true;
            const res = await this.$ajax.post(url, postData);
            this.saveLoading = false;
            if (res.status === 200) {

            }
        },
        targetInputEvent: debounce(function(e, item, index) {
            // console.log('targetInputEvent::', e, item, index);
            this.saveSentence(e, item, index);
        }, 500),
        targetBlurEvent: debounce(function(e, item, index) {
            // console.log('targetBlurEvent::', e, item, index);
            this.saveSentence(e, item, index);
        }, 100),

        async handleConfirmTranslation() {
            // 弹出确认开始翻译的对话框
            this.$refs.confirmDialog.setShow(true);
        },

        async startTranslation() {
            this.confirmLoading = true;
            let url = `${toolsApi.confirmVideoSentence}?taskId=${this.info.taskId}`;
            const res = await this.$ajax.get(url);
            this.confirmLoading = false;
            if (res.status === 200) {
                this.$message.success('确认译文成功，1s后返回列表页');
                await tools.sleep(1000);
                this.$router.replace('/tools/videoTranslate/index');
             }
        },
        cancelTranslation() {
            // 取消翻译操作，返回编辑页面
            this.$message.info('取消翻译操作');
        },
        
    },
    mounted() {
        this.initTaskInfo();
        this.initSubtitles();
        // document.querySelector(".el-main").style="overflow-y:hidden";
    },
    beforeDestroy() {
        // document.querySelector(".el-main").style="";
    },
}
</script>
<style lang="scss" scoped>
.video-editer-page {
    height: 100%;
    .editer-toolbar {
        display: flex;
        align-items: center;
        padding: 10px 0px;
        > .back-btn{
            width: 30px;
            height: 30px;
            font-size: 20px;
            line-height: 30px;
            cursor: pointer;
            text-align: center;
            &:hover,&:focus{
                background-color: #0000001A;
                border-radius: 4px;
            }
            > i{
                font-weight: 600;
            }
         }
    }

    .doc-title {
         font-weight: bold;
    }
    .editer-wrapper {
        height: calc(100vh - 46px - 52px - 20px);
        display: flex;
        .editer-main {
            height: 100%;
            display: flex;
            flex-direction: column;
            flex: 1;
            background-color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            .editer-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 20px;
                border-bottom: 1px solid #eee;
                font-size: 14px;

                .right {
                    display: flex;
                    align-items: center;

                    .icon {
                        margin-left: 8px ; 
                        margin-right: 8px ; 
                    }

                    .icon {
                        font-size: 18px; 
                    }

                    .help-icon {
                        font-size: 18px;
                        color: #555;
                    }
                }
            }
            .editer-content {
                flex: 1;
                overflow-y: auto;
                .item {
                    padding-right:20px;
                    position: relative;
                    border-bottom: 1px solid #eee;
                    &:hover {
                        background-color: #e6f9f1;
                    }
                    .width-50 {
                        width: 50px;
                    }
                    .item-number {
                        text-align: center;
                        padding-top: 10px ;
                        color: #999;
                        font-size: 14px;
                    }
                     .time-container {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        padding-top: 10px ;
                        .ib-time {
                            margin-right: 1px;  
                            font-size: 15px;
                        }
                        
                        .time-bucket-wrapper {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .time-bucket {
                                color: #808080;
                                font-size: 14px;
                                height: 24px;
                                border-bottom: 1px solid #0000001A;
                                box-sizing: border-box;
                            }

                            .short-line {
                                width: 100%;  // 让短线与时间戳等宽
                                height: 1px;
                                background-color: #eee;
                                margin-top: 5px;  // 短线和时间戳之间的间距
                            }
                        }
                    }

                    .sentence-content {
                        
                         > div {
                            display: flex;
                            line-height: 1.5;
                            box-sizing: border-box;
                            &.source {
                                font-size: 14px;
                                padding: 10px 0;  
                            }
                            
                            &.target {
                                display: flex; 
                                align-items: flex-start; 
                                margin-bottom: 10px;
                                .icon-edit {
                                    text-align: center;
                                    padding-top: 8px;
                                }
                                
                                .target-text {
                                    flex: 1;
                                    position: relative;  
                                    outline: none;
                                    border: 2px solid transparent;
                                    border-radius: 10px;
                                    transition: border-color 0.3s;
                                    font-size: 14px;
                                    padding: 5px;  
                                    margin: 0;  
                                    min-height: 1.5em;
                                    
                                    &:focus {
                                        border-color: #7DD6B3;
                                    }
                                    
                                }
                            }
                        }
                    }
                }
            }
            .editer-footer{
                padding: 15px 0;
                text-align: center;
            }
        }
        .video-main {
            margin-left: 10px;
            width: 500px;
           // min-height: 500px;
            background-color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            overflow: hidden; // 防止内容溢出
            align-self: flex-start; 
            display: flex;
            flex-direction: column;
            .video-header {
                        padding: 10px;
                        //font-weight: bold;
                        text-align: center;
                        border-bottom: 1px solid #eee;
                        font-size: 14px;
                    }

            video {
                width: 100%;
                height: 310px;
                object-fit: contain; // 保持视频比例
                background-color: #eee;
            }

            .video-controls {
                display: flex;
                align-items: center;
                padding: 10px;
                border-bottom: 1px solid #eee;
                // background-color: #f5f5f5;

                .play-pause-btn {
                    cursor: pointer;
                    margin-right: 10px;
                    font-size: 22px;
                }

                .time-display {
                    flex: 1;
                    text-align: center;
                    font-size: 14px;
                }
            }
        }

    }
}
</style>
